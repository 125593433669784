@import "../../../palette";

.dashboard-header {
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 auto;
    padding: 16px;

    .title {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex: 0 0 auto;
        flex-flow: row nowrap;
        font-size: 20px;
        font-weight: 700;

        img {
            height: 16px;
            margin: 0 0 0 8px;
            width: 16px;
        }
    }

    .spacer {
        flex: 1 1 auto;
    }
}



