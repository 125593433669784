.tab {
  width: 100%;
  overflow: auto;

  .menu {
    padding-bottom: 15px !important;
  }

  .active {
    padding: 0 4px 8px !important;
  }
}

.background {
  margin: 0;
  padding: 0;
  background-color: transparent;
}
