@import '../../palette';

.page-not-found {
  margin-top: 5%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: $white;

  .error-code {
    line-height: 100px;
    font-size: 100px;
    font-weight: bold;
    color: $gray;
  }

  .error-title {
    line-height: 100px;
    font-size: 100px;
    font-weight: bold;
  }

  .error-message {
    margin-top: 20px;
    font-size: 18px;
  }

  .link-suggestion {
    margin-top: 10px;
    font-size: 18px;
  }
}