@import '../../../../palette';

.ui.popup.gauge-tooltip {
  background-color: $pitch-black;
  border: 0;
  color: $white;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
  padding: 6px;
}

.gauge-tooltip:before {
  background: $pitch-black !important;
  border: 0 !important;
}

.gaugecontainer {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  color: $text;
  .gauge {
    background-color: $dashboards-gauge;
    border-radius: 32px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin: 0 auto;
    padding: 16px 0 16px 0;

    .light {
      background-color: $dashboards-gauge-off;
      border: 1px solid $dashboards-gauge-off;
      border-radius: 50%;
      margin: 0 auto 8px;

      &.is-active {
        &.upper {
          background-color: $red;
        }
        &.middle {
          background-color: $yellow;
        }
        &.lower {
          background-color: $green;
        }
      }
    }
  }
}
