// colors
$white: #ffffff;
$almost-white: #f7f7f7;
$medium-white: #f2f4f7;
$wild-sand: #f5f5f5;
$very-light-gray: #dedede;
$light-gray: #e6e6e6;
$silver: #cdcaca;
$gray: #ababab;
$medium-gray: #8a8a8a;
$heavy-gray: #787878;
$sonic-silver: #767474;
$silver-chalice: #ABABAB;
$dark-gray: #666666;
$very-dark-gray: #45464d;
$very-light-black: #3a3b40;
$light-black: #404040;
$jet: #343434;
$dark: #303236;
$medium-black: #2d2e32;
$heavy-black: #2c2e37;
$very-black: #2b2b2d;
$almost-black: #28292e;
$black: #262626;
$cod-gray: #191818;
$pitch-black: #000000;
$red: #ff0000;
$light-red: #f74f5d;
$yellow: #ffd300;
$very-light-green: #add5bd;
$light-green: #55b58c;
$green: #00d300;
$hansoft-green: #12936f;
$dark-green: #157756;
$very-dark-green: #396055;
$blue: #26b9fc;
$dark-blue: #2f6db5;
$light-cyan: #add8e6;
$very-light-cyan: #d8f0fd;
$white-cyan: #eaf7ff;

// color-variables
$box-shadow: $black;
$background: $medium-black;
$board-background: $medium-black;
$text: $white;
$secondary-text: $gray;
$hover: $very-light-black;
$border: $very-dark-gray;
$titlebar-background: $black;
$todo-table-header-background: $almost-black;
$itemdetails-screen-background: $almost-black;
$itemdetails-toolbar-background: $almost-black;
$itemdetails-toolbar-hover: $very-light-black;
$itemdetails-disabled: $heavy-black;
$itemdetails-disabled-text: $dark-gray;
$dashboards-background: $medium-black;
$dashboards-tile-background: $dark;
$dashboards-itemview-row-background: $heavy-black;
$dashboards-gauge: $very-dark-gray;
$dashboards-gauge-off: $dark;
$boards-card-background: $almost-black;
$boards-droppable-card: $very-dark-green;
$board-column-background: $very-dark-gray;
$link: $blue;
$droplist-background: $very-light-black;
$droplist-selected: $very-dark-gray;
$droplist-hover: $very-dark-gray;


