@import '../../palette';
.Login {
  font-family: 'Roboto', sans-serif;
}

.LoginForm {
  display: block;
  margin-bottom: 20px;

  input {
    width: 340px !important;
    border: 1px solid $medium-gray !important;
    border-radius: 2px !important;
    font-family: 'Roboto', sans-serif !important;
  }
}

.SsoSuggestion {
  display: block;
  text-align: left;
  margin-bottom: 2px;
}

.SsoLink {
  cursor: pointer;
  margin-left: 5px;
}

.SsoButton {
  margin-top: 20px !important;
  background-color: $hansoft-green !important;
  font-family: 'Roboto', sans-serif !important;
  color: $white !important;
  min-width: 350px;
}

.LoginButton {
  text-align: right;
  background-color: $hansoft-green !important;
  font-family: 'Roboto', sans-serif !important;

  :hover {
    text-align: right;
    background-color: $dark-green !important;
    font-family: 'Roboto', sans-serif !important;
  }

  :active {
    text-align: right;
    background-color: $light-green !important;
    font-family: 'Roboto', sans-serif !important;
  }
}

.LoginMessageStyle {
  width: 400px;

  .Label {
    font-weight: bold;
  }
}

.LoginPageStyle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  background-image: url('../../images/login-helix.png');
  background-repeat: no-repeat;
  background-color: $very-black;

  .Center {
    outline: 1px solid $black;
    padding: 30px;
    background-color: $white;
    border-bottom: 4px solid $hansoft-green;
    border-top: 34px hidden $very-black;
  }

  .PasswordField {
    display: block;
    margin-bottom: 20px !important;
  }

  .Button {
    text-align: right;
    background-color: $hansoft-green !important;
  }
}
