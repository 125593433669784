@import '../../../palette';

.droplist {
  background-color: $droplist-background !important;
  color: $white !important;

  &.ui.popup {
    border: 1px solid $droplist-background;
    box-shadow: 3px 3px 3px $box-shadow !important;
  }

  &.ui.bottom.popup:before {
    background-color: $droplist-background;
    border: none;
  }

  &.ui.bottom.center.popup:before {
    display: none;
  }

  &.ui.top.popup:before {
    background-color: $droplist-background;
    border: none;
  }

  &.ui.top.center.popup:before {
    display: none;
  }

  &.ui.bottom.right.popup:before {
    display: none;
  }

  &.ui.bottom.left.popup:before {
    display: none;
  }
}