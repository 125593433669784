@import '../../palette';

.task-create {
  .no-projects-text {
    text-align: center;
  }
  .heading {
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    flex-flow: row nowrap;
    font-size: 16px;
    font-weight: 700;
    justify-content: space-between;
    padding: 16px;
    background-color: $background;
    svg {
      path {
        stroke: $text !important;
      }
      cursor: pointer;
      height: 24px;
      width: 24px;
    }
  }
  .field {
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
    .label {
      margin-top: 5px;
      margin-bottom: 25px;
      min-width: 200px;
      width: 200px;
    }
    .singleselect-container {
      width: 100%;

      .select-view {
        display: flex;

        .dropdown-icon {
          display: flex !important;
          margin-left: auto;
        }
      }
    }
    .multiline-editor {
      width: 100%;
    }
  }
  .divider {
    background-color: $border;
    height: 2px;
    margin: 32px 0;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
    button {
      border: 1px solid transparent;
      border-radius: 4px;
      background-color: $hansoft-green;
      color: white;
      font-weight: bold;
      cursor: pointer;
      padding: 8px 16px;
      outline: none;
      &:hover,
      &:focus {
        background-color: lighten($hansoft-green, 10%);
      }
      &:disabled {
        color: $light-gray;
        cursor: not-allowed;
        background-color: darken($hansoft-green, 10%);
      }
    }
  }
}
