@import '../../../../../palette';

.show-options {
  margin: 0 8px 0 0;
  height: 100%;
  min-width: 60px;
  color: $text;

  .icon {
    color: $droplist-background;
  }

  .menu {
    left: initial;
    right: 0;
    background-color: $droplist-background !important;
    box-shadow: 2px 2px 2px $almost-black !important;

  }

  .icon {
    margin: 5px 0 0 5px !important;
  }
  .checkmark {
    margin-right: 5px !important;
    padding-left: 0 !important;
  }

  div.item {
    padding-left: 25px !important;
  }

  div.item-with-checkbox {
    padding-left: 0 !important;
    .text {
      color: $text !important;
    }
  }


  &.ui.dropdown .menu>.item:hover {
    background-color: $droplist-hover !important;
    .text {
      color: $text !important;
    }

    .icon-inactive {
      color: $droplist-hover !important;
    }
  }
}
