@import '../../../../palette';

.filterbar {
  background-color: $background;
  border-top: 1px solid $border;
  color: $text;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 8px;

  .filterbutton {
    &.is-active {
      .singleselect {
        color: $hansoft-green;
      }
    }
  }

  .filterbutton + .filterbutton {
    margin: 0 0 0 1%;
  }

  @media only screen and (max-width: 1500px) {
    .filterbutton + .filterbutton {
      margin: 1% 0 0 1%;
    }
  }

  .singleselect-container {
    min-width: 200px;

    .select-view {
      display: flex;

      .dropdown-icon {
        display: flex !important;
        margin-left: auto;
      }
    }
  }

  input {
    margin: 0 16px 0 0;
  }

  .show-dropdown {
    margin-left: auto;
  }

  .clear-all {
    margin-left: 10px;
    min-width: 80px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    color: $link;
  }
}
