@import '../../../palette';

.searchInput {
  border: 1px solid $light-black;
  margin: 0 24px 0 0;
  padding: 0 8px;
  max-width: 30%;

  &:focus {
    border: 1px solid $hansoft-green;
    outline: none;
  }
}
