@import '../../../../palette';

.link-item {
  min-height: 30px;
  min-width: 150px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .link-information {
    display: flex;
    width: 90%;
    min-width: 150px;

    .relation {
      width: 100%;
      max-width: 90px;
      margin-right: 10px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .link-description {
      display: block;
      width: 100%;
    }

    .description-no-access {
      display: block;
      color: $gray;
    }
  }

  .icons {
    margin-left: 10px;
    display: flex;
    .icon {
      height: 16px;
      width: 16px;
      margin: 0 8px 8px;
    }
  }

  .resourcesIcon {
    height: 16px;
    width: 16px;
    margin: 8px 8px 8px auto;
  }

  .points {
    color: $light-black;
  }
}
