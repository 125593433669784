@import '../../../palette';

.toDoBoards {
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
  flex-direction: column;
  align-items: flex-start;
  background-color: $background !important;
}

.container {
  height: calc(100vh - 100px);
  background-color: $background !important;
  overflow: auto;
}

.kanban-board {
  width: 100%;
}
