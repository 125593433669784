@import '../../palette';

.tooltip-content {
  background-color: $droplist-background !important;
  color: $white !important;
  border-color: $droplist-background !important;
  border: 1px solid $droplist-background;
  box-shadow: 3px 3px 3px $box-shadow !important;
  max-width: 400px !important;
}
