@import '../../palette';

.dashboards {
  :global {
    @import '../../palette';
  }
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  overflow: hidden;
  height: 100%;
  color: $text;
  background-color: $dashboards-background;

  .emptyState {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    justify-content: center;
    color: $text;

    .icon {
      height: 48px;
      margin: 0 0 16px 0;
      width: 48px;
    }
  }
}
