@import '../../../palette';

.ItemDetail-Page {
  background-color: $itemdetails-screen-background;
  color: $text;
  display: flex;
  flex-flow: column nowrap;
  height: calc(100% - 56px);

  .ui.borderless.inverted.menu {
    border-radius: 0;
  }

  .ui.segment {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .singleselect-container {
    min-width: 250px;

    .select-view {
      display: flex;
      align-items: center;

      .dropdown-icon {
        display: flex !important;
        margin-left: auto;
      }
    }
  }

  .ui.secondary.inverted.menu {
    .item {
      color: $text !important;
    }
  }

  .milestones {
    display: flex;
  }

  .right-top {
    display: flex;
    flex-flow: row nowrap;
  }

  .description {
    display: flex;
    align-items: center;
    padding-right: 8px;
    margin: 0 16px 16px;
  }

  .sprint {
    flex: 0 0 auto;
    margin: 0 16px 24px;
  }
}

.cover-image {
  cursor: pointer;
  max-height: 60px;
  max-width: 200px !important;
  object-fit: contain;
  margin-right: 10px;
}

.tab-background {
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.ItemDetail-ButtonLink {
  color: $white;
  font-size: 1em;
  text-decoration: none;
}

#multiline_link .ui.secondary {
  overflow-x: auto;
  padding: 0 0 15px 0;
}

.itemdetail-topbar {
  background-color: $itemdetails-screen-background;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0 0 16px 0;
  padding: 8px;

  .watch {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    svg {
      fill: $text;
      stroke: $text;
      height: 16px;
      margin-right: 8px;
      width: 16px;
    }
    margin-right: 16px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.relationlist {
  cursor: pointer;
  height: 18px;
  margin: 0 8px 0;
  width: 18px;
}

.relationlist > image {
  height: 18px;
  width: 18px;
}

.relationlist-popup {
  display: flex;
  flex-flow: column nowrap;
  max-width: 384px;
  min-width: 264px;
}

.relationlist-popup .relationlist-items {
  max-height: 400px;
  overflow-y: auto;
  padding: 8px;
}

.relation {
  align-items: center;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.relation + .relation {
  margin: 8px 0 0 0;
}

.relation .relation-icon {
  align-items: center;
  display: flex;
  height: 16px;
  margin: 0 8px 0 0;
  width: 16px;
}

.relation .relation-text {
  font-size: 0.9em;
}

.relation .relation-subtext {
  font-size: 0.85em;
}

.relation .relation-link {
  color: $pitch-black;
  font-size: 1em;
}

.relation .relation-link:hover {
  text-decoration: underline;
}

.hansoftlink {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  margin: 0 16px 0 0;
  .icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
}

.hansoftlink:hover {
  text-decoration: underline;
}

.hansoftlink > img {
  height: 18px;
  margin: 0 8px 0 0;
  width: 18px;
}

.timespent-container {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}

.timespent-label {
  color: rgb(171, 172, 176);
  margin: 0 8px 0 0;
}

.timespent-input {
  flex: 1 1 auto;
}

/* Todo: move out Semantic overrides? */

#multiline_link .ui.secondary.menu {
  border: transparent !important;
}

#multiline_link .ui.secondary.menu .item {
  padding: 0 4px 8px !important;
}

.multiline-toolbar .rdw-option-wrapper {
  background: initial;
}

.multilinetoolbar-icon,
.multilinetoolbar-code .rdw-option-wrapper,
.multilinetoolbar-code .rdw-option-active {
  border: initial !important;
  background: $itemdetails-toolbar-background !important;
  color: $text !important;
  height: 24px !important;
  min-width: initial !important;
  padding: 2px 4px !important;
  margin: 0 4px 0 0 !important;
}

.multilinetoolbar-icon.rdw-option-active,
.multilinetoolbar-code .rdw-option-active {
  box-shadow: initial;
  background: $itemdetails-toolbar-hover !important;
}

.multilinetoolbar-code {
  padding: 0 !important;
}

.multilinetoolbar-icon .rdw-option-wrapper {
  align-items: center;
  display: flex;
  border: initial !important;
  background: initial;
  height: initial;
  min-width: initial;
  padding: 0;
}

.multilinetoolbar-icon .rdw-option-wrapper:hover,
.multilinetoolbar-code .rdw-option-wrapper:hover {
  background: initial;
  box-shadow: initial;
}

.multilinetoolbar-icon:hover,
.multilinetoolbar-code .rdw-option-wrapper:hover {
  background-color: $itemdetails-toolbar-hover;
  box-shadow: initial;
}

.multilinetoolbar-icon img {
  height: 16px;
  width: 16px;
}

.multiline-editor {
  background-color: $background;
  border: 1px solid $border;

  .rdw-colorpicker-modal {
    background-color: $droplist-background !important;
    border: 1px solid $droplist-background !important;
    box-shadow: 3px 3px 3px $box-shadow !important;
    color: $text !important;
    height: 100px;

    .rdw-colorpicker-modal-style-label-active {
      border-bottom: 2px solid $hansoft-green;
    }
    .rdw-colorpicker-modal-options {
      overflow: hidden !important;
      height: 70px;
      .rdw-colorpicker-option {
        box-shadow: none;
        height: 20px;
        background-color: $droplist-background;
        border: 1px solid $droplist-background !important;
      }

      .rdw-colorpicker-option:hover {
        border: 1px solid $hansoft-green !important;
      }

      .rdw-colorpicker-option-active {
        border: 1px solid $hansoft-green !important;
      }

      .rdw-colorpicker-cube {
        height: 12px;
        width: 12px;
        border: 1px solid $droplist-background !important;
        &:hover {
          box-shadow: none !important;
        }
      }
    }
  }
  .rdw-link-modal {
    margin-left: -200px;
    height: 220px;
    width: 300px;
    background-color: $droplist-background !important;
    border: 1px solid $droplist-background !important;
    box-shadow: 3px 3px 3px $box-shadow !important;
    color: $text !important;

    .rdw-link-modal-input {
      background-color: $background;
      border: 1px solid $border;
      color: $text;
      border-radius: 0;
      height: 30px;
      padding: 0 8px;
      width: 95%;

      &::selection {
        color: $pitch-black !important;
        background: $light-cyan !important;
      }
      &:focus {
        border: 1px solid $hansoft-green;
        outline: none;
      }
    }

    .rdw-link-modal-target-option {
      display: flex;
      align-items: center;

      span {
        color: $gray;
      }

      input[type='checkbox'] {
        width: 20px !important;
        margin-right: 5px;
        accent-color: $background !important;
        border: 1px solid $border !important;
        &:focus {
          border: 1px solid $hansoft-green !important;
          outline: none !important;
        }
      }
    }

    .rdw-link-modal-buttonsection {
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      margin-left: 105px;
    }

    .rdw-link-modal-buttonsection > .rdw-link-modal-btn {
      background-color: $hansoft-green;
      color: $text;
      border: none;
      border-radius: 4px;
      &:hover {
        background-color: lighten($hansoft-green, 5%);
        color: $text;
        box-shadow: none;
      }
      &:disabled {
        background-color: darken($hansoft-green, 5%);
        cursor: not-allowed;
      }
    }
    .rdw-link-modal-buttonsection > .rdw-link-modal-btn ~ .rdw-link-modal-btn {
      background-color: $medium-black;
      border: 1px solid $very-dark-gray;
    }
  }
}

.multlilinetoolbar {
  background-color: $itemdetails-toolbar-background !important;
  border: initial !important;
  padding: initial !important;
}

.rdw-editor-main pre {
  color: $text !important;
  border: initial;
  background: initial;
  padding: initial;
}

.hs-list-item {
  margin-left: 2.5em !important;
}

.public-DraftStyleDefault-block,
.public-DraftStyleDefault-ul,
.public-DraftStyleDefault-ol {
  margin: 0 !important;
}

.rdw-inline-wrapper {
  order: 0;
}

.rdw-code-wrapper {
  order: 1;
}

.rdw-colorpicker-wrapper {
  order: 2;
}

.rdw-remove-wrapper {
  order: 3;
}

.rdw-list-wrapper {
  order: 4;
}

.rdw-link-wrapper {
  order: 5;
}

@media only screen and (max-width: 512px) {
  .vertical.reflex-container {
    flex-direction: column !important;
  }

  .reflex-splitter {
    display: none !important;
    visibility: hidden !important;
  }

  .reflex-element {
    flex: 1 1 auto !important;
  }

  .reflex-splitter + .reflex-element {
    margin-top: 32px;
  }
}
