@import '../../../../palette';

.item-view {
  overflow: auto;
  ::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
  color: $text;

  table {
    thead {
      tr {
        margin-bottom: -2px;
      }
    }

    th {
      padding: 0 8px 5px 8px;
      text-align: left;
    }

    th,
    td {
      border-right: 1px solid $border;
    }

    .header {
      .cell {
        font-weight: 700;
      }
    }

    td {
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 8px 0 8px;
      white-space: nowrap;
      position: relative;
    }
  }
}
