@import '../../../palette';

.dashboards {
  color: $text;
  background-color: $dashboards-background;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  .emptystate {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    justify-content: center;
    color: $text;

    .icon {
      height: 48px;
      margin: 0 0 16px 0;
      width: 48px;
    }
  }
}

.charts {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row wrap;
  overflow-y: auto;
  padding: 0 16px 32px 16px;

  .column + .column {
    margin: 0 0 0 32px;
  }

  &.singlecolumn {
    .column {
      width: calc(100% - 32px);
    }
  }
  &.twocolumns {
    .column {
      width: calc(50% - 32px);
    }
  }
  &.threecolumns {
    .column {
      width: calc(33% - 32px);
    }
  }
  &.fourcolumns {
    .column {
      width: calc(25% - 32px);
    }
  }
  &.highway {
    .column:nth-child(1),
    .column:nth-child(4) {
      width: calc(20% - 32px);
    }
    .column:nth-child(2),
    .column:nth-child(3) {
      width: calc(30% - 32px);
    }
  }
  &.centerlane {
    .column:nth-child(1),
    .column:nth-child(3) {
      width: calc(30% - 32px);
    }
    .column:nth-child(2) {
      width: calc(40% - 32px);
    }
  }
  &.sidewalk {
    .column:nth-child(even) {
      width: calc(60% - 32px);
    }
    .column:nth-child(odd) {
      width: calc(40% - 32px);
    }
  }
}

// Mobile

@media only screen and (max-width: 768px) {
  .charts {
    .column {
      width: calc(100%) !important;
    }
    .column + .column {
      margin: 0 !important;
    }
  }
}
