@import '../../../palette';

.icon-multi-select {
  display: flex;
  padding: 5px;
  height: 30px;

  .label {
    margin-right: 5px;
  }

  .icon-wrapper {
    border: 1px solid $border;
    height: 30px;
    width: 30px;
    margin-top: -5px;
    cursor: pointer;
  }

  .selected {
    border-color: $hansoft-green !important;
  }

  .icon {
    margin: 6px 3px 0 6px;
    width: 16px;
    height: 16px;
  }
}
