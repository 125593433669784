@import '../../../../palette';

.board-column {
    flex: 1 1;
    margin: 5px;
    max-height: 600px;
    max-width: 20%;
    min-width: 15%;
    border-radius: 5px;
    background-color: $board-column-background;

  .title {
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    margin-left: 15px;
    cursor: default !important;
    color: $text !important;
    .icon {
      margin-left: auto;
      margin-right: 15px;
      margin-top: 5px;
      height: 16px;
      width: 16px;
    }

    .card-counter {
      margin-left: 5px;
      color: $secondary-text !important;
    }
  }
  .body {
    max-height: 550px;
    overflow-y: auto;
  }
  padding: 1px;
  min-height: auto;
}

.column-not-droppable {
  opacity: 0.4 !important;
}
