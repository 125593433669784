@import '../../../palette';

.board-title {
  font-size: 18px;
  width: 99%;
  display: flex;
  margin: 10px !important;
  vertical-align: bottom;
  color: $text !important;

  .dropdown.icon {
    margin-bottom: 5px !important;
  }

  .tasks-counter {
    margin-left: 5px;
    margin-top: 2px;
    font-size: 14px;
    color: $secondary-text!important;
  }

  .sub-title {
    font-size: 12px;
    font-weight: normal;
    margin-left: 5px;
    vertical-align: text-bottom;
    margin-top: 2px;
  }
}

.board-container {
    background-color: $board-background;
    color: $text !important;
    overflow: auto;
    max-height: 700px;
    min-height: 400px;
    margin: 10px;
    text-align: center;
    border-radius: 5px;
    border: 2px $border solid;
    transition: max-height 500ms;
    overflow-y: hidden;
}

.board-container-collapsed {
    background-color: $board-background;
    color: $text !important;
    overflow: auto;
    max-height: 61px;
    margin: 10px;
    text-align: center;
    border-radius: 5px;
    border: 2px $border solid;
    transition: max-height 500ms;
}

.board {
  display: flex !important;
  justify-content: flex-start;
  margin: 0 auto;
  align-items: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
}
